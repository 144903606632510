import saveRepairCodes, { ISaveRepairCode } from '../data/saveRepairCode'

export const castSaveRepairCode = (
  code: string | undefined | null
): ISaveRepairCode | undefined => {
  const codeStr: string = code ?? ''
  return saveRepairCodes.includes(codeStr as ISaveRepairCode)
    ? (codeStr as ISaveRepairCode)
    : undefined
}
