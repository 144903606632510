const getRepairLabel = (code: string) => {
  switch (code.toUpperCase()) {
    case 'DOX':
      return 'Désoxydation'
    case 'LCD':
      return 'Réparation écran'
    case 'SWAP':
      return 'Echange standard'
    case 'BAT':
      return 'Remplacement batterie'
    case 'BTN_VOL':
      return 'Remplacement bouton volume'
    case 'BTN_POW':
      return 'Remplacement bouton On/Off'
    case 'BTN_HOME':
      return 'Remplacement bouton home'
    case 'BTN_VIB':
      return 'Remplacement vibreur'
    case 'VIB':
      return 'Remplacement vibreur'
    case 'VIBREUR':
      return 'Remplacement vibreur'
    case 'PCONNECTOR':
      return 'Remplacement connecteur de charge'
    case 'CON':
      return 'Remplacement connecteur de charge'
    case 'CAM_REAR':
      return 'Remplacement caméra arrière'
    case 'CAM_FRONT':
      return 'Remplacement caméra avant'
    case 'RNL':
      return 'Mise à jour logiciel'
    case 'BACK_COVER':
      return 'Réparation protection arrière'
    case 'TCT':
      return 'Remplacement tactile'
    case 'HP':
    case 'HAUT PARLEUR':
      return 'Remplacement haut parleur'
    case 'DIAG':
      return 'Diagnostic'
    case 'ECO':
      return 'Écouteurs'
    case 'MIC':
      return 'Remplacement micro'
    case 'MULTI':
      return 'Réparations multiples'
    case 'VITRE':
      return 'Lentille de Caméra Arrière'
    case 'OTH':
      return 'OTH'
    default:
      return ''
  }
}

export default getRepairLabel
