import getRepairLabel from './getRepairLabel'

// FIXME: to delete when label will be cleanup upstream
const fixRepairLabel = (code: string): string => {
  const label = getRepairLabel(code)
  if (label) {
    return label
  }

  return ''
}

export default fixRepairLabel
